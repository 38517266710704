<!--
 * @Descripttion:登录页面
 * @version:1.0.0
 * @Author: huchongyuan
 * @Date: 2021-03-07 22:56:54
 * @LastEditors: huchongyuan
 * @LastEditTime: 2021-04-21 22:48:58
-->
<template>
    <div id="login">
        <Form ref="loginForm" class="loginForm" :model="loginForm" :label-width="80">
            <FormItem label="用户名" prop="userName">
                <Input type="text" v-model="loginForm.userName" />
            </FormItem>
            <FormItem label="密码" prop="password">
                <Input type="password" v-model="loginForm.password" />
            </FormItem>
            <FormItem>
                <Button type="primary" @click="handleSubmit('loginForm')">登录</Button>
                <Button @click="handleReset('loginForm')" style="margin-left: 8px">重置</Button>
            </FormItem>
        </Form>
    </div>
</template>
<script>
import LoginRequest  from '@/api/login';
export default {
  name: 'Login',
  data() {
    return {
      loginForm: {
        userName: '',
        password: '',
      },
    };
  },
  methods: {
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          LoginRequest.submitLogin(this.loginForm).then(data=>{
            if(data && data.code =="1000"){
              // 查询数据字典并且缓存到sessionStorage中;
              LoginRequest.queryAllDis({}).then((resp)=>{
                if(resp && resp.code ==="1000"){
                  var result = resp.body;
                  this.setSessionStorage("login","1");
                  this.setSessionStorage("DD001",JSON.stringify(result["DD001"]));
                  this.setSessionStorage("DD002",JSON.stringify(result["DD002"]));
                  this.setSessionStorage("DD003",JSON.stringify(result["DD003"]));
                  this.setSessionStorage("DD004",JSON.stringify(result["DD004"]));
                  this.$router.push({"name":"Search"})
                }
              });
            }else{
              this.$Message.error(data.message);
            }
          });
        }
      });
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    setSessionStorage(key,value){
      sessionStorage.setItem(key,value);
    }
  },
};
</script>
<style lang="less">
#login{
  width:100%;
  height:100%;
  position: relative;
  background-image:url('https://bzss-1256420415.cos.ap-guangzhou.myqcloud.com/bz/bzyw/shouyetupian.png');
  background-repeat: no-repeat;
  background-size:100% 100%;
  background-origin:content-box;
}
.loginForm{
  width:300px;
  height:160px;
  position:absolute;
  right:200px;
  bottom:52%;
}
#login .ivu-form .ivu-form-item-label{
  color:#fff;
}
</style>
